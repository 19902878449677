import { palette } from '../../definitions'

const Divider = {
  styleOverrides: {
    // Name of the slot
    root: {
      borderColor: palette.common.divider,
    },
  },
}

export default Divider
