import { palette, typography, breakpoints, spacing } from './definitions'
import { createTheme } from '@mui/material/styles'

import { alpha } from '@mui/material'
import Divider from './components/Divider'
import CssBaseline from './components/CssBaseline'

const colors = [
  'primary',
  'error',
  'warning',
  'info',
  'success',
  'viewAsTenant',
  'text',
  'accent',
]

export default createTheme({
  palette,
  typography,
  breakpoints,
  spacing,
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderRadius: '100px',
          fontSize: '16px',
          maxHeight: '2.5rem',
          color: palette.common.white,
          fontWeight: 500,
          fontFamily: '"CircularStd"',
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            border: `1px solid ${palette.common.outline}`,
            color: alpha(palette.text.main, 0.75),
            fontWeight: 300,
            lineHeight: '1.1rem',
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: 'hsla(6, 95%, 62%, 0.05)',
            },
            '& .MuiButton-startIcon': {
              mr: 1.5,
            },
          },
        },
      ],
    },
    MuiCssBaseline: CssBaseline,
    MuiDivider: Divider,
  },
})
