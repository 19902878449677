const MuiCssBaseline = {
  styleOverrides: `
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');

  @font-face {
    font-family: 'CircularStd';
    font-weight: 600;
    src: url('/fonts/CircularStd-Bold/CircularStd-Bold.eot');
    src: url('/fonts/CircularStd-Bold/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/CircularStd-Bold/CircularStd-Bold.woff') format('woff'),
    url('/fonts/CircularStd-Bold/CircularStd-Bold.ttf') format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 400;
    src: url('/fonts/CircularStd-Medium/CircularStd-Medium.eot');
    src: url('/fonts/CircularStd-Medium/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/CircularStd-Medium/CircularStd-Medium.woff') format('woff'),
    url('/fonts/CircularStd-Medium/CircularStd-Medium.ttf') format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 300;
    src: url('/fonts/CircularStd-Book/CircularStd-Book.eot');
    src: url('/fonts/CircularStd-Book/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CircularStd-Book/CircularStd-Book.woff') format('woff'),
          url('/fonts/CircularStd-Book/CircularStd-Book.ttf') format('truetype');
          font-display: swap;
  } `,
}

export default MuiCssBaseline
