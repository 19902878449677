const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 991,
    lg: 1280,
    xl: 1440,
    uw: 1920, // UltraWide
  },
};

export default breakpoints;
