import React, { useMemo } from 'react'
import { Box, Button, Skeleton, ThemeProvider, Typography } from '@mui/material'
import { ReactSVG } from 'react-svg'
import icon from '../assets/icon.svg'
import { HelpOutline, OpenInNew } from '@mui/icons-material'
import { usePreferences } from '../hooks'
import theme from '../theme'

type CompanyBanner = {
  envId: string | undefined
  apiKey: string | undefined
  error: string | undefined
  isLoading: boolean
}

const CompanyBanner = (props: CompanyBanner) => {
  const { data, error, isLoading } = usePreferences(
    props.envId ?? '',
    props.apiKey ?? ''
  )

  return (
    <ThemeProvider
      theme={{
        ...theme,
        palette: { ...theme.palette, primary: { main: data?.primaryColor } },
      }}
    >
      <Box
        display="flex"
        flex={1}
        alignItems={'center'}
        maxHeight={'100vh'}
        height={'100vh'}
        width={'100%'}
        sx={{
          position: 'sticky',
          top: 0,
          transition: 'all 0.3s ease',
        }}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        {props.isLoading || isLoading ? (
          <Skeleton width={120} height={120} variant="circular" />
        ) : data?.connectLogoData ? (
          <Box
            maxWidth={120}
            component={'img'}
            src={data?.connectLogoData}
          ></Box>
        ) : (
          <ReactSVG src={icon}></ReactSVG>
        )}
        <Typography
          fontSize="36px"
          mt={'32px'}
          fontWeight={700}
          letterSpacing={'-0,72px'}
        >
          {props.isLoading || isLoading ? (
            <Skeleton width={200} variant="text" />
          ) : (
            data?.connectTitle ?? 'Connect your App.'
          )}
        </Typography>
        <Typography
          textAlign="center"
          mt={'24px'}
          fontSize="18px"
          fontWeight={450}
          color={props.error ? 'error.main' : 'text.light'}
          lineHeight={'140%'}
        >
          {props.isLoading || isLoading ? (
            <Skeleton width={350} variant="text" />
          ) : props.error ? (
            data?.connectError || props.error + ', please contact the admin.'
          ) : (
            data?.connectDescription || 'Connect your app to Hotglue.'
          )}
        </Typography>
        <Box display="flex" gap={'24px'} mt={'34px'}>
          {props.isLoading || isLoading ? (
            <>
              <Skeleton width={120} height={40} variant="rectangular" />
              <Skeleton width={120} height={40} variant="rectangular" />
            </>
          ) : (
            <>
              {data?.connectMainLink && <Button
                component={'a'}
                href={data?.connectMainLink}
                target={'_blank'}
                variant="contained"
                color="primary"
                sx={{ px: 4, py: 2 }}
                endIcon={<OpenInNew sx={{ fontSize: '16px' }} />}
              >
                {data?.connectMainLinkText ?? 'Visit Main Website'}
              </Button>}
              {data?.connectHelpLink && <Button
                component={'a'}
                href={data?.connectHelpLink}
                target={'_blank'}
                variant="outlined"
                color="secondary"
                sx={{ px: 4, py: 2 }}
                endIcon={<HelpOutline sx={{ fontSize: '16px' }} />}
              >
                {data?.connectHelpLinkText ?? 'Help Instructions'}
              </Button>}
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default CompanyBanner
