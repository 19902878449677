import React, { useState, useMemo, useEffect } from 'react'
import { Connections, useHotglue } from '@hotglue/widget'
import useToken from '../hooks/useToken'
import useParams from '../hooks/useParams'
import { Box, Paper, TextField, Typography, debounce } from '@mui/material'
import { usePreferences } from '../hooks'

const HotglueConnections = (props: unknown) => {
  const token = useParams('token')
  const { setOptions } = useHotglue()
  const { data } = useToken(token)
  const { data: prefsData, error, isLoading } = usePreferences(data?.envId ?? '', data?.apiKey ?? '')
  const [search, setSearch] = useState('')
  const [optionsLoaded, setOptionsLoaded] = useState(false)

  useEffect(() => {
    if(data?.jwt && prefsData?.jwtStrict && !isLoading) {
      setOptions({
        jwtToken: data?.jwt,
      })
    }

    if(prefsData && !isLoading) {
      setOptionsLoaded(true)
    }
  }, [prefsData?.jwtStrict, data?.jwt, setOptions, isLoading])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e?.target?.value)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.clear()
    }
  })

  const filterFlow = (flow: string) => {
    if (data?.flow) return data.flow === flow
    return true
  }
  const filterEntity = (flowId: string, entityId: string) => {
    if(search)
      return entityId.includes(search)
    if (data?.flow && data?.entityId)
      return entityId === data.entityId && entityId.includes(search)
    return true
  }
  if (!data) return <></>
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      flex={1}
      pt={4}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& .hg-flows-container:last-child > .hotglue-w-MuiBox-root > hr': {
          display: 'none',
        },
      }}
    >
      <Paper
        elevation={1}
        sx={{ display: 'flex', flexDirection: 'column', p: 4, width: '100%' }}
      >
        <Box display="flex" flexDirection="column" gap={'12px'} mb={4}>
          {data?.type === 'all' && (
            <TextField
              placeholder="Search app"
              type="text"
              onChange={debouncedResults}
            />
          )}
        </Box>

        {optionsLoaded && <Connections
          tenant={data.tenant}
          filterFlow={filterFlow}
          hideBackButtons={true}
          filterEntity={filterEntity}
        />}
      </Paper>
    </Box>
  )
}

export default HotglueConnections
