const palette = {
  type: 'light',
  primary: {
    lightest: 'hsla(6, 95%, 95%, 1)',
    light: 'hsla(6, 95%, 85%, 1)',
    main: 'hsla(6, 95%, 62%, 1)',
    dark: 'hsla(6, 95%, 39%, 1)',
    hover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FEE8E6',
    darkest: 'hsla(6, 95%, 20%, 1)',
    contrastText: '#fff',
    gradient: 'linear-gradient(90.15deg, #FF2651 -42.76%, #FF2651 -41.32%, #FF7B2C 102.01%)',
  },
  secondary: {
    main: 'hsla(255, 8%, 40%, 1)',
  },
  error: {
    lightest: 'hsla(348, 95%, 95%, 1)',
    light: 'hsla(348, 95%, 85%, 1)',
    main: 'hsla(348, 95%, 57%, 1)',
    dark: 'hsla(348, 95%, 20%, 1)',
  },
  warning: {
    lightest: 'hsla(40, 100%, 95%, 1)',
    light: 'hsla(40, 100%, 85%, 1)',
    main: 'hsla(40, 100%, 57%, 1)',
    dark: 'hsla(40, 100%, 20%, 1)',
    gradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FFF7E6;',
  },
  info: {
    lightest: 'hsla(199, 98%, 95%, 1)',
    light: 'hsla(199, 98%, 85%, 1)',
    main: 'hsla(199, 98%, 48%, 1)',
    dark: 'hsla(199, 98%, 20%, 1)',
  },
  viewAsTenant: {
    dark: 'hsla(199, 98%, 85%, 1)',
    main: 'white',
    light: 'hsla(199, 98%, 95%, 1)',
    contrastText:'hsla(199, 98%, 48%, 1)',
  },
  success: {
    lightest: 'hsla(122, 57%, 95%, 1)',
    light: 'hsla(122, 57%, 85%, 1)',
    main: 'hsla(120, 57%, 49%, 1)',
    dark: 'hsla(122, 57%, 20%, 1)',
  },
  accent: {
    main: '#282143',
    dark: '#433c5d',
    disabled: 'rgba(67, 60, 93, 0.25);',
  },
  text: {
    primary: '#18171D',
    secondary: '#302D39',
    tertiary: '#898593',
    body: 'rgba(48, 45, 57, 0.80)',
    disabled: '#B3B1BA',

    // TODO: Possibly move these to 'neutral' to make it easier to understand.
    // slightly modified color props for button style generation.
    lightest: '#B3B1BA',
    lightish: '#64616B',
    light: '#898593',
    main: '#302D39',
    dark: '#18171D',
    contrastText: '#18171D',
  },
  // Used by default for card buttons, dropdown triangles, list item icons and buttons
  action: {
    active: '#6B7280',
    focus: 'rgba(55, 65, 81, 0.12)',
    hover: 'rgba(55, 65, 81, 0.04)',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)',
    activatedOpacity: 0.12,
    disabledOpacity: 0.38,
    hoverOpacity: 0.55,
    selectedOpacity: 0.08,
  },
  common: {
    outline: '#D9D9D9',
    divider: '#f0f0f0',
    white: '#fff',
  },
  outline: {
    main:'#D9D9D9',
    contrastText: '#000',

  }

};

export default palette;
