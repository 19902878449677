import * as React from 'react'
import HotglueConfig, { Connections } from '@hotglue/widget'
import useParams from './hooks/useParams'
import useToken from './hooks/useToken'
import { Box, Container, Divider } from '@mui/material'
import HotglueConnections from './components/HotglueConnections'
import CompanyBanner from './components/CompanyBanner'

export default function App() {
  const token = useParams('token')
  const { data, error, isLoading } = useToken(token)
  return (
    <Box display="flex" height={'100vh'} sx={{
      backgroundImage: 'url("./grid.svg")'
    }}>
      <Container maxWidth="xl">
        <Box display="flex" gap={'40px'}>
          <CompanyBanner isLoading={isLoading} error={error} envId={data?.envId} apiKey={data?.apiKey}/>
          {data && !isLoading && (
            <HotglueConfig
              config={{
                apiKey: data.apiKey,
                envId: data.envId,
              }}
            >
              <HotglueConnections />
            </HotglueConfig>
          )}
        </Box>
      </Container>
    </Box>
  )
}
