import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './theme'
import { SWRConfig } from 'swr'
const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement!)
import { fetcher } from './api'
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <App />
    </SWRConfig>
  </ThemeProvider>
)
