import { AxiosError } from 'axios'
import useSWR from 'swr'
import { API, AdminApiError } from '../api'

export type ConnectPreferencesPayload = {
  connectTitle: string
  connectLogoName: string
  connectLogoData: string
  connectMainLink: string
  connectHelpLink: string
  connectError: string
  connectDescription: string
  primaryColor: string
  connectMainLinkText: string
  connectHelpLinkText: string
  jwtStrict: boolean
}

const usePreferences = (envId: string, apiKey: string) => {
  if (!envId)
    return {
      data: undefined,
      error: 'Missing envId in params',
      isLoading: false,
    }

  const { data, error, isLoading } = useSWR<any, string>([
    `/${envId}/preferences`,
    apiKey,
    API.CLIENT,
  ])

  const connectPrefs: ConnectPreferencesPayload = {
    ...data?.connectPrefs,
    primaryColor: data?.uiPreferences?.primaryColor ?? '#fa5442',
    jwtStrict: data?.uiPreferences?.jwtStrict ?? false,
  }
  return {
    data: connectPrefs,
    error,
    isLoading,
  }
}

export default usePreferences
