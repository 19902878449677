import { lineHeight } from '@mui/system'
import palette from './palette'
import { typographyClasses } from '@mui/material'

const typography = {
  fontFamily: ['CircularStd', 'sans-serif'].join(','),
  htmlFontSize: 16,
  fontSize: 16,
  fontWeightLight: 200,
  fontWeightRegular: 300,
  fontWeightMedium: 300,
  fontWeightBold: 600,
  button: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  h1: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  h2: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  h4: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '1rem',
    fontWeight: 400,
  },
  body1: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: 1.5,
  },
  body2: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: 1.5,
  },
  caption: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '0.825rem',
    fontWeight: 300,
    lineHeight: 1.25,
  },
  subtitle: {
    fontFamily: ['CircularStd', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    color: palette.text.light,
  },
}

export default typography
