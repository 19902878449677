import useSWR from 'swr'
import { API } from '../api'

export type InfoPayload = {
  envId: string
  apiKey: string
  tenant: string
  entityId: string
  flow: string
  type: string
  jwt: string
}

const useToken = (token: string) => {
  if (!token)
    return {
      data: undefined,
      error:
        'Missing token in the url, please check the url to see if a token is being passed or contact an admin',
      isLoading: false,
    }

  const { data, error, isLoading } = useSWR<InfoPayload, string>([
    `/connect?token=${token}`,
    undefined,
    API.ADMIN,
  ])

  return { data, error, isLoading }
}

export default useToken
