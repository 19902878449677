import axios, { AxiosError } from 'axios'

export interface AdminApiError {
  errorMessage?: string
}

export enum API {
  ADMIN = 'admin',
  CLIENT = 'client',
}

const adminApiInstance = axios.create({
  baseURL: 'https://qa.api.hotglue.xyz/latest',
  headers: {
    'Content-Type': 'application/json',
  },
})

const clientApiInstance = axios.create({
  baseURL: 'https://qa.client-api.hotglue.xyz',
  headers: {
    'Content-Type': 'application/json',
  },
})

export type Error = {
  message?: string
}

export const fetcher = (params: [string, string, API]) => {
  const [url, apiKey, apiType] = params
  console.log('[fetcher] url:', url, 'using:', apiType)

  //compare the givin enum to use the correct instance
  const instance = apiType === API.CLIENT ? clientApiInstance : adminApiInstance
  return instance
    .get(url, {
      headers: apiKey ? { 'x-api-key': apiKey } : {},
    })
    .then((res) => res.data)
    .catch((err: AxiosError<AdminApiError>) => {
      throw err?.response?.data.errorMessage
    })
}
